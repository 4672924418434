import axios from "axios";

import store from "@/store/index.js";
import i18n from "/src/language/index.js";
console.log(i18n);
// console.log(i18n.t("loginAgainTips"));


let isNotRegist = window.location.href.indexOf('registerEmail')===-1
const neterr = isNotRegist?i18n.t("neterr"):"网络异常，请稍后再试"
const serverConfig = {
  baseURL: "/api", // 请求基础地址,可根据环境自定义
  useTokenAuthorization: true, // 是否开启 token 认证
};

// 创建 axios 请求实例
const serviceAxios = axios.create({
  baseURL: serverConfig.baseURL, // 基础请求地址
  timeout: 10000, // 请求超时设置
  withCredentials: false, // 跨域请求是否需要携带 cookie
});

// 创建请求拦截
serviceAxios.interceptors.request.use(
  (config) => {
    // 如果开启 token 认证
    if (serverConfig.useTokenAuthorization) {
      config.headers["token"] = store.getters.token; // 请求头携带 token
    }
    // 设置请求头
    if (!config.headers["content-type"]) {
      // 如果没有设置请求头
      config.headers["content-type"] = "application/json"; // 默认类型
      // if (config.method === "post") {
      //   config.headers["content-type"] = "application/x-www-form-urlencoded"; // post 请求
      //   config.data = qs.stringify(config.data); // 序列化,比如表单数据
      // } else {
      //   config.headers["content-type"] = "application/json"; // 默认类型
      // }
    }
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

// 创建响应拦截
serviceAxios.interceptors.response.use(
  (res) => {
    let _data = res.data;
    // 处理自己的业务逻辑，比如判断 token 是否过期等等
    // 代码块
    // console.log("data", _data);
    let { code, msg } = _data;
    if (code === 0 || code === 1013) {
      //特殊情况1013 需要处理用户信息
      return _data;
    } else {
      if (code === 1003) {
        store.dispatch("loginOut");
        store.commit("setIsShowLogin", true);
        return Promise.reject(isNotRegist?i18n.t("loginAgainTips"):"登录过期，请重新登录");
      } else {
        return Promise.reject(msg ? msg : neterr);
      }
    }
  },
  (error) => {
    let message = neterr;
    console.log("error.response.status", error.response);
    if (error && error.response) {
      switch (error.response.status) {
        case 1003:
          message = isNotRegist?i18n.t("loginAgainTips"):"登录过期，请重新登录"
          store.dispatch("loginOut");
          store.commit("setIsShowLogin", true);
          break;
        default:
          message = neterr;
      }
    }
    return Promise.reject(message);
  }
);
export default serviceAxios;
