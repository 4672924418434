import Vue from "vue";
import Vuex from "vuex";
import Api from "@/api";
import createPersistedState from "vuex-persistedstate";
import { Message } from "element-ui";
Vue.use(Vuex);

export default new Vuex.Store({
  plugins: [createPersistedState()], //默认存localstorage
  state: {
    userInfo: {},
    orderNum: 0,
    showLogin: false,
    language: "zh",
  },
  getters: {
    isAdmin(state) {
      return state.userInfo.admin;
    },
    token(state) {
      return state.userInfo.token;
    },
    isShowLogin(state) {
      return state.showLogin;
    },
    accountName(state) {
      return state.userInfo.accountName;
    },
    orderNum(state) {
      return state.orderNum;
    },
    language(state) {
      return state.language;
    },
  },
  mutations: {
    setLanguage(state, language) {
      state.language = language;
    },
    setUser(state, userInfo) {
      state.userInfo = userInfo;
    },
    setIsShowLogin(state, isShowLogin) {
      state.showLogin = isShowLogin;
    },
    setOrderNum(state, num) {
      state.orderNum = num || num === 0 ? num : state.orderNum + 1;
    },
  },
  actions: {
    async login({ commit }, loginForm) {
      return new Promise((resolve, reject) => {
        Api.login({
          ...loginForm,
        })
          .then((res) => {
            const { code, data } = res;
            if (code === 0) {
              commit("setUser", data);
              resolve(true);
            } else {
              reject(false);
            }
          })
          .catch((err) => {
            Message.error(err);
            reject(false);
          });
      });
    },
    loginOut({ commit }) {
      // 清除本地存储中的userInfo
      // localStorage.removeItem("USERINFO");
      commit("setOrderNum", 0);
      commit("setIsShowLogin", false);
      commit("setUser", {});
      localStorage.removeItem("vuex");
      // 重置state中的userInfo
    },
  },
});
