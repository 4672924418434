module.exports = {
  login: "登录",
  homePage: "首页",
  lesson: "课程",
  article: "文章",
  member: "会员",
  shoppingCart: "购物车",
  my: "我的",
  personalCenter: "个人中心",
  loginOut: "退出",
  memberCenter: "会员中心",
  classRecords: "上课记录",
  myInformation: "我的信息",
  orderRecord: "订单记录",
  courseSchedule: "开课计划",
  class: "课程",
  date: "日期",
  mentor: "导师",
  certificate: "证书",
  downloadCertificate: "下载证书",
  basicInformation: "基本信息",
  fullName: "姓名",
  country: "国家",
  phone: "联系方式",
  province: "省份",
  city: "城市",
  street: "区",
  email: "邮箱",
  address: "地址",
  zipCode: "邮编",
  modify: "修改",
  cancel: "取消",
  save: "保存",
  yes: "是",
  no: "否",
  receivingInformation: "收件信息",
  required: "代表必填",
  orderNum: "订单号",
  money: "金额",
  status: "状态",
  operate: "操作",
  view: "查看详情",
  pay: "去支付",
  noData: "暂无数据",
  orderDetails: "订单详情",
  totalAmount: "总金额",
  recipientInformation: "收款人信息",
  addCourse: "添加课程",
  name: "名称",
  types: "上课形式",
  editStudents: "编辑学生",
  editCourse: "编辑课程",
  courseDetails: "课程详情",
  timeZone: "时区",
  studentsInformation: "学生信息",
  startDate: "开始日期",
  endDate: "结束日期",
  startTime: "开始时间",
  endTime: "结束时间",
  assistant: "助教老师",
  course: "课程",
  registeredStudents: "注册学生",
  studentName: "学生姓名",
  isPay: "是否购买",
  delete: "删除",
  createCertificate: "创建证书",
  edit: "编辑",
  certificateDisplayName: "证书展示名称",
  search: "搜索",
  searchResult: "搜索结果",
  addSelectedStudents: "添加所选学生",
  noSearchResult: "暂无搜索结果",
  registerForEmail: "为此邮箱注册新学生",
  registeNewStudents: "注册新学生",
  matchNames: "匹配现有名称",
  enter: "请输入",
  select: "请选择",
  quickLoginRegist: "快速登录注册",
  pleaseLoginAccount: "请登录账号",
  pleaseRegistAccount: "请注册账号",
  accountEmail: "用户名/邮箱",
  peyanoea: "请输入账户或者邮箱",
  password: "密码",
  pleaseEnterPwd: "请输入密码",
  pleaseEnteracoremail: "请输入账户名或者邮箱",
  pleaseEnterConfirmPwd: "请输入确认密码",
  pwdError: "两次输入密码不一致",
  forgetPwd: "忘记密码",
  register: "注册",
  account: "用户名",
  enterAccount: "请输入账户名",
  confirmpwd: "确认密码",
  sure: "确定",
  getNewThoughEmail: "通过邮件找回密码",
  sedNewPwd: "通过邮箱发送新密码",
  iAgree: "我同意",
  privacyConditions: "隐私条件",
  privacyPolicy: "隐私政策",
  courseLink: "课程链接",
  reikiArticle: "灵气文章",
  contactUs: "联系我们",
  chaneseReiki: "华人灵气研究协会",
  wx: "微信号",
  learnMore: "了解更多",
  reikiEffect: "灵气作用",
  product: "产品",
  price: "单价",
  quantity: "数量",
  orderNumber: "订单编号",
  orderInformation: "订单信息",
  payNow: "立即支付",
  modeOfPay: "支付方式",
  payAbleMoney: "应付金额",
  tobepaid: "待支付",
  canceled: "已取消",
  refunded: "已退款",
  completed: "已完成",
  onLine: "线上",
  offLine: "线下",
  onOffAsync: "线上线下同步",
  courseTypes: "课程类别",
  courseText: "课程文本",
  courseName: "课程名称",
  courseLevel: "课程等级",
  courseDescription: "课程描述",
  certificatePrice: "证书价格",
  uploadCertificate: "上传证书",
  upload: "上传",
  addCourseTypes: "课程类别新增",
  editCourseTypes: "课程类别编辑",
  editCourese: "编辑课程",
  pleaseEnterCourseName: "请输入课程名称",
  pleaseEnterCourseLevel: "请输入课程等级",
  pleaseEnterCourseTypes: "请输入课程类别",
  arangeLength: "长度在 0 到 100 个字符",
  coursePrice: "请输入课程价格",
  certificateTemplate: "请上传证书模版",
  addSuccess: "添加成功",
  editSuccess: "编辑成功",
  selectCourse: "请选择课程",
  selectStartDate: "请选择起始日期",
  selectEndDate: "请选择结束日期",
  selectStartTime: "请选择起始时间",
  selectEndime: "请选择结束时间",
  enterAssistant: "请输入助教老师",
  enterCorrectEmail: "请输入正确的邮箱",
  certificateName: "请输入证书名称",
  enterFirstName: "请输入名",
  enterLastName: "请输入姓",
  enterCountry: "请选择国家",
  enterProvince: "请输入省份",
  enterCity: "请输入城市",
  enterStreet: "请输入区",
  enterZipCode: "请输入邮编",
  enterAddress: "请输入地址",
  enterPhone: "请输入手机号",
  enterEmail: "请输入邮箱",
  saveSuccess: "保存成功",
  deleteSuccess: "删除成功",
  isDeleteCourse: "确认删除该课程吗？",
  paymentSuccessful: "支付成功",
  payResult: "支付结果",
  paymentFailed: "支付失败",
  firstName: "名",
  lastName: "姓",
  payAganin: "去订单记录重新支付",
  payAgain2: "重新选择该订单支付",
  enterFirst: "请输入名",
  enterLast: "请输入姓",
  completePersonalInfo: "去个人中心完善个人信息？",
  registSuccess: "注册成功",
  concatStudens: "已成功发送邮件，请及时联系学生填写密码",
  startLessThanEnd: "开始日期应该小于结束日期",
  endShowBigThanStart: "结束日期应该大于开始日期",
  uploadSize: "请上传5M以内大小的文件",
  uploadFileType: "请上传pdf格式文件",
  agreePolice: "请勾选条款和条件、隐私政策",
  newpwd: "新密码",
  refistTips:
    "您的导师已为您的邮箱添加会员账号，请您在下方填写您的个人信息并创建账户",
  findpwdTips: "请您在下方设置新的密码",
  deleteTips: "此操作将永久删除该学生, 是否继续？",
  loginAgainTips: "登录过期，请重新登录",
  neterr: "网络异常，请稍后再试",
  tokenexpire: "token过期",
  sendEmailAgain: "再次发送邮件",
  sendSuccess: "发送成功",
  emailSuccess: "发送成功,请去邮箱中查看",
};
