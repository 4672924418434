module.exports = {
  login: "Login",
  homePage: "Home",
  lesson: "Course",
  article: "Article",
  member: "Member",
  shoppingCart: "Cart",
  my: "My",
  yes: "Yes",
  no: "No",
  personalCenter: "Personal center",
  loginOut: "Login out",
  memberCenter: "MEMBER CENTER",
  classRecords: "Class Records",
  myInformation: "My Information",
  orderRecord: "Order Record",
  courseSchedule: "Course schedule",
  class: "Class",
  date: "Date",
  mentor: "Dentor",
  certificate: "Certificate",
  downloadCertificate: "Download certificate",
  basicInformation: "Basic information",
  fullName: "Fullname",
  country: "Country",
  phone: "Phone",
  province: "Province",
  city: "City",
  street: "Street",
  email: "Email",
  address: "Address",
  zipCode: "ZipCode",
  modify: "Modify",
  cancel: "Cancel",
  save: "Save",
  receivingInformation: "Receiving information",
  required: "Is required",
  orderNum: "Order number",
  money: "Money",
  status: "Status",
  operate: "Operate",
  view: "View",
  pay: "Pay",
  noData: "No data",
  orderDetails: "Order details",
  totalAmount: "Total amount",
  recipientInformation: "Recipient Information",
  addCourse: "Add course",
  name: "Name",
  types: "Types",
  editStudents: "Edit students",
  editCourse: "Edit course",
  courseDetails: "Course details",
  timeZone: "Time zone",
  studentsInformation: "Students information",
  startDate: "StartDate",
  endDate: "EndtDate",
  startTime: "StartTime",
  endTime: "EndTime",
  assistant: "Assistant",
  course: "Course",
  registeredStudents: "Registere students",
  studentName: "Student name",
  isPay: "paied",
  delete: "Delete",
  createCertificate: "CreateCertificate",
  edit: "Edit",
  certificateDisplayName: "CertificateDisplayName",
  search: "Search",
  searchResult: "SearchResult",
  addSelectedStudents: "Add selected students",
  noSearchResult: "No search result",
  registerForEmail: "Register a new student for this emai",
  registeNewStudents: "Registe new students",
  matchNames: "Match existing names",
  enter: "Enter",
  quickLoginRegist: "Quick login&registration",
  pleaseLoginAccount: "Please login account",
  pleaseRegistAccount: "Please regist account",
  accountEmail: "Account name/email",
  peyanoea: "Please enter your account name or email address",
  password: "password",
  pleaseEnterPwd: "Please enter password",
  pleaseEnteracoremail: "Please enter your account name or email address",
  pleaseEnterConfirmPwd: "Please enter a confirmation password",
  pwdErroe: "The passwords entered twice are inconsistent",
  forgetPwd: "Forget password",
  register: "Register",
  account: "Account",
  confirmpwd: "Confirm password",
  sure: "Sure",
  getNewThoughEmail: "Retrieve password through email",
  sedNewPwd: "Send a new password through email",
  iAgree: "I Agree",
  privacyConditions: "Privacy conditions",
  privacyPolicy: "Privacy policy",
  courseLink: "Course Link",
  reikiArticle: "Reiki Article",
  contactUs: "Contact us",
  chaneseReiki: "CHINESE REIKI RESEARCH ASSOCIATION",
  wx: "Wechat",
  learnMore: "More",
  reikiEffect: "REIKI EFFECT",
  product: "Product",
  price: "Price",
  quantity: "Quantity",
  orderNumber: "Order number",
  orderInformation: "order information",
  payNow: "Pay now",
  modeOfPay: "ModeOfPay",
  payAbleMoney: "Pay able money",
  tobepaid: "To be paid",
  canceled: "Canceled",
  refunded: "Refunded",
  completed: "Completed",
  onLine: "Online",
  offLine: "Offline",
  onOffAsync: "Online and offline synchronization",
  courseTypes: "Course types",
  courseText: "Course text",
  courseName: "Course name",
  courseLevel: "Course level",
  courseDescription: "Course description",
  certificatePrice: "Certificate price",
  uploadCertificate: "UploadCertificate",
  upload: "Upload",
  select: "Select",
  addCourseTypes: "Add course types",
  editCourseTypes: "Edit course types",
  editCourese: "EditCourese",
  pleaseEnterCourseName: "Please enter course name",
  pleaseEnterCourseLevel: "Please enter course level",
  pleaseEnterCourseTypes: "Please enter courseTypes",
  arangeLength: "Length from 0 to 100",
  coursePrice: "Please enter course price",
  certificateTemplate: "Please upload certificate template",
  addSuccess: "Add success",
  editSuccess: "Edit success",
  selectCourse: "Please select course",
  selectStartDate: "Please select start date",
  selectEndDate: "Please select end date",
  selectStartTime: "Please select start time",
  selectEndime: "Please select end time",
  enterAssistant: "Please enter assistant",
  enterCorrectEmail: "Please enter the correct email address",
  certificateName: "Please enter certificate name",
  enterFirstName: "Please enter first name",
  enterLastName: "Please enter last name",
  enterProvince: "Please enter province",
  enterCountry: "Please enter country",
  enterCity: "Please enter city",
  enterStreet: "Please enter street",
  enterZipCode: "Please enter zipcode",
  enterAddress: "Please enter address",
  enterPhone: "Please enter phone",
  enterEmail: "Please enter email",
  saveSuccess: "save success",
  deleteSuccess: "Delete success",
  isDeleteCourse: "Are you sure to delete this course?",
  paymentSuccessful: "payment successful",
  payResult: "Pay result",
  paymentFailed: "payment failed",
  firstName: "First name",
  lastName: "Last name",
  payAganin: "Go to the order record and payAgain",
  payAgain2: "Re select the payment for this order",
  enterAccount: "Please enter account",
  enterFirst: "Please enter your first name",
  enterLast: "Please enter your last name",
  completePersonalInfo: "Go to improve personal information？",
  registSuccess: "registered successfully",
  concatStudens:
    "The email has been successfully sent. please contact the student promptly to fill in the password",
  startLessThanEnd: "The start date should be earlier than the end date",
  endShowBigThanStart: "The end date should be greater than the start date",
  uploadSize: "Please upload files up to 5M in size",
  uploadFileType: "Please upload a PDF format file",
  agreePolice: "Please check the terms and conditions, privacy policy",
  newpwd: "new password",
  refistTips:
    "Your mentor has added a member account to your email. Please fill in your personal information below and create an account",
  findpwdTips: "Please set a new password below",
  deleteTips:
    "This operation will permanently delete the student. Do you want to continue？",
  loginAgainTips: "Login expired, please log in again",
  neterr: "Network abnormality, please try again later",
  tokenexpire: "Token expires",
  sendEmailAgain: "Send email again",
  sendSuccess: "Send success",
  emailSuccess: "Successfully sent, please check your email",
};
