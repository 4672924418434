import Vue from "vue";
// 引入自己的语言包
import chinese from "./zh"; // 中文
import english from "./en"; // 英文
// 国际化
import VueI18n from "vue-i18n";
Vue.use(VueI18n);
import store from "@/store";

// element ui 国际化
import Element from "element-ui";
import enLocale from "element-ui/lib/locale/lang/en.js";
import zhLocale from "element-ui/lib/locale/lang/zh-CN.js";

// 配置i18n语言包
const messages = {
  zh: {
    ...chinese,
    ...zhLocale,
  },
  en: {
    ...english,
    ...enLocale,
  },
};

// 国际化配置
const i18n = new VueI18n({
  locale: store.getters.language || "zh", // 默认中文
  messages,
});

// vue配置
Vue.use(Element, {
  i18n: (key, value) => i18n.t(key, value),
});

export default i18n;
