import serviceAxios from "./httpInstance";
//按需带出封装的函数方法
export const to = (promiseA) => {
  // 传入原来Promise对象, 我这里负责成功/失败结果的接收
  const PromiseB = promiseA
    .then((res) => {
      return [null, res];
    })
    .catch((err) => {
      return [err, null];
    });
  //这里用来统一接处理成功/失败结果
  return PromiseB;
};
// axios内响应拦截器用success判断
// 返回成功就进上面的then, 返回失败进catch
// 原来是直接返回到逻辑页面, 而这里被to包进来
// 先走这里中转一下, 在return一个数组给逻辑页面

const api = {
  login: "/ow/auth/login",
  regist: "/ow/auth/register",
  courseTypeList: "/ow/courseType/list",
  coureseTypeAdd: "/ow/courseType/add", //开课计划新增
  courseTemplateAdd: "/ow/course/template/add", //课程模版新增
  courseTemplateDelete: "/ow/course/template/del/{templateId}", //课程删除
  courseTemplateList: "/ow/course/template/pageList", //课程模版列表
  cartAdd: "/shop/cart/add", //创建证书
  courseAll: "/ow/course/template/list", //所有课程名称
  cartPageList: "/shop/cart/pageList", //购物车列表
  orderList: "/ow/order/listPage", //订单记录
  courseList: "/ow/course/list", //开课计划 课程列表
  courseAdd: "/ow/course/add", //开课计划 课程添加
  getpPersonInfo: "/ow/user/info", //个人信息获取
  upDatePersonInfo: "/ow/user/update", //更新
  classRecord: "/ow/classRecord/list", //上课记录列表
  inviteNewUser: "/ow/course/user/register", //邀请新用户注册
  certificateUrlDownlod: "/ow/oss/certificateUrl",
  submitOrder: "/ow/order/submitOrder",
  sedPwdByEmail: "/ow/auth/sendEmailResetPwd",
  memberwall: "/ow/course/template/member",
  modifyPwd: "/ow/auth/resetPwd",
  payOrder: "/ow/order/pay",
  sendEmailAgain: "/ow/course/user/register/sendEmail",
};
function login(data) {
  return serviceAxios({
    url: api.login,
    method: "post",
    data,
  });
}
function registerUser(data) {
  return serviceAxios({
    url: api.regist,
    method: "post",
    data,
  });
}
//课程类别列表
function getCourseTypeList(params) {
  return serviceAxios({
    url: api.courseTypeList,
    method: "get",
    params,
  });
}
// 新增课程类别
function coureseTypeAdd(data) {
  return serviceAxios({
    url: api.coureseTypeAdd,
    method: "post",
    data,
  });
}

// 修改课程类别
function courseModify(data) {
  const { id, typeName } = data;
  return serviceAxios({
    url: `/ow/courseType/edit/${id}`,
    method: "post",
    data: { typeName },
  });
}

// 课程计划课程编辑
function courseEdit(data) {
  const { courseId, courseInfo } = data;
  return serviceAxios({
    url: `/ow/course/update/${courseId}`,
    method: "post",
    data: {
      ...courseInfo,
    },
  });
}

// 教师新增课程
function courseAdd(data) {
  return serviceAxios({
    url: api.courseAdd,
    method: "post",
    data,
  });
}
//课程模版新增’
function courseTemplateAdd(data) {
  return serviceAxios({
    url: api.courseTemplateAdd,
    method: "post",
    data,
  });
}

// 删除课程模版
function courseTemplateDelete(templateId) {
  return serviceAxios({
    url: `/ow/course/template/del/${templateId}`,
    method: "delete",
    data: {},
  });
}

//编辑课程模版  "/ow/course/template/edit/{tempId}",
function courseTemplateEdit(data) {
  const { id, ...res } = data;
  return serviceAxios({
    url: `/ow/course/template/edit/${id}`,
    method: "post",
    data: res,
  });
}

// 课程模版列表
function courseTemplateList(params) {
  return serviceAxios({
    url: api.courseTemplateList,
    method: "get",
    params,
  });
}

//创建证书
function cartAdd(data) {
  return serviceAxios({
    url: api.cartAdd,
    method: "post",
    data,
  });
}

function shopCartDel(id) {
  return serviceAxios({
    url: `/shop/cart/del/${id}`,
    method: "delete",
    data: {},
  });
}

// 课程模版列表
function cartPageList(params) {
  return serviceAxios({
    url: api.cartPageList,
    method: "get",
    params,
  });
}
// 删除课程下的学生 /ow/course/user/del/{courseId}/{userId}
function courseStudentDelete(data) {
  const { courseId, userId } = data;
  return serviceAxios({
    url: `/ow/course/user/del/${courseId}/${userId}`,
    method: "delete",
    data: {},
  });
}
// 编辑学生证书名称 /ow/course/user/edit/{courseId}
function iditeStudentCertifityName(data) {
  const { courseId, certificateName, userId } = data;
  return serviceAxios({
    url: `/ow/course/user/edit/${courseId}`,
    method: "post",
    data: { certificateName, userId },
  });
}

// 订单记录
function orderList(params) {
  return serviceAxios({
    url: api.orderList,
    method: "get",
    params,
  });
}

// 订单记录详情
function orderListDetail(orderNo) {
  return serviceAxios({
    url: `/ow/order/detail/${orderNo}`,
    method: "get",
    params: {},
  });
}

function courseAll(params) {
  return serviceAxios({
    url: api.courseAll,
    method: "get",
    params,
  });
}
// 课程计划列表
function courseList(params) {
  return serviceAxios({
    url: api.courseList,
    method: "get",
    params,
  });
}

//获取个人信息
function getpPersonInfo(params) {
  return serviceAxios({
    url: api.getpPersonInfo,
    method: "get",
    params,
  });
}

//更新个人信息
function upDatePersonInfo(data) {
  return serviceAxios({
    url: api.upDatePersonInfo,
    method: "post",
    data,
  });
}

//上课记录列表 classRecord
function classRecord(params) {
  return serviceAxios({
    url: api.classRecord,
    method: "get",
    params,
  });
}
//通过邮件匹配用户
function byEmailMatchUser(email) {
  return serviceAxios({
    url: `/ow/user/match/${email}`,
    method: "get",
    params: {},
  });
}
//注册新学生发短信
function inviteNewUser(data) {
  return serviceAxios({
    url: api.inviteNewUser,
    method: "post",
    data,
  });
}

// 匹配现有学生
function registAlreadyStudent(params) {
  const { certificateName, courseId, userId } = params;
  return serviceAxios({
    url: `/ow/course/user/add/${courseId}`,
    method: "post",
    data: { certificateName, userId },
  });
}

function getCourseStudents(data) {
  const { courseId, pageIndex, pageSize } = data;
  return serviceAxios({
    url: `/ow/course/stu/list/${courseId}`,
    method: "get",
    params: { pageIndex, pageSize },
  });
}

// pdf下载地址
function certificateUrlDownlod(params) {
  return serviceAxios({
    url: api.certificateUrlDownlod,
    method: "get",
    params,
  });
}

//提交订单
function submitOrder(data) {
  return serviceAxios({
    url: api.submitOrder,
    method: "post",
    data,
  });
}

//通过邮箱发送新密码
function sedPwdByEmail(params) {
  return serviceAxios({
    url: api.sedPwdByEmail,
    method: "get",
    params,
  });
}
//会员墙
function memberwall(params) {
  return serviceAxios({
    url: api.memberwall,
    method: "get",
    params,
  });
}

//通过邮件发送密码
function modifyPwd(data) {
  return serviceAxios({
    url: api.modifyPwd,
    method: "post",
    data,
  });
}

//支付订单
function payOrder(data) {
  return serviceAxios({
    url: api.payOrder,
    method: "post",
    data,
  });
}

//再次发送邮件
function sendEmailAgain(data) {
  return serviceAxios({
    url: api.sendEmailAgain,
    method: "post",
    data,
  });
}

export default {
  registerUser,
  login,
  getCourseTypeList,
  coureseTypeAdd,
  courseModify,
  courseAdd,
  courseTemplateAdd,
  courseTemplateDelete,
  courseTemplateEdit,
  courseTemplateList,
  cartAdd,
  shopCartDel,
  cartPageList,
  courseStudentDelete,
  iditeStudentCertifityName,
  orderList,
  courseAll,
  courseList,
  getpPersonInfo,
  upDatePersonInfo,
  classRecord,
  orderListDetail,
  byEmailMatchUser,
  inviteNewUser,
  registAlreadyStudent,
  getCourseStudents,
  certificateUrlDownlod,
  courseEdit,
  submitOrder,
  sedPwdByEmail,
  memberwall,
  modifyPwd,
  payOrder,
  sendEmailAgain,
};
